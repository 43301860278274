import * as React from "react"
import { useState } from "react"
import { Container, Box, Typography, Button } from "@mui/material"
import AUListTable from "../../src/TranscriptFlow/TableView/AUListTable"
import { useRouter } from "next/router"
import AUAlert from "../../src/AUAlert"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { ProtectedPage, useAuth } from "../../src/context/AuthContext"
import BrandProgress from "../../src/BrandProgress"
import AUDashboardButton from "../../src/AUDashboardButton"
import { AUTableCellMenuAction } from "../../src/AUTableCellMenu"
import {
  ArchivesTableContextProvider,
  ArchivesTableContext,
} from "../../src/context/ArchivesTableContext"
import TranscribeStatusAccordion from "../../src/UploadFlow/TranscribeStatusAccordion"
import { TypeAnimation } from "react-type-animation"
import theme from "../../src/theme"
import { Add } from "@mui/icons-material"

function Archives() {
  const { user, userLoading } = useAuth()
  const router = useRouter()
  const [error, setError] = useState(null)

  const { t } = useTranslation()

  const onCellClick = (archive) => {
    router.push({ pathname: "/archives/" + archive.id + "/" + archive.name })
  }

  return (
    <Container maxWidth="md">
      <TranscribeStatusAccordion></TranscribeStatusAccordion>
      {userLoading && <BrandProgress />}
      {!userLoading && user && (
        <>
          {error != null && (
            <AUAlert title={error.title} text={error.message} />
          )}
          <ArchivesTableContextProvider>
            <ArchivesTableContext.Consumer>
              {(context) => (
                <>
                  {!context.isLoading ? (
                    <>
                      {context.archives.length > 0 ? (
                        <Container maxWidth="md">
                          <Box sx={{ my: 2, position: "relative" }}>
                            <AUDashboardButton
                              icon={<Add />}
                              action={() => router.push("/archives/add")}
                            ></AUDashboardButton>
                            <br />
                            <br />

                            <AUListTable
                              showsTypeColumn={false}
                              nameColumn={t("name")}
                              onCellCallback={onCellClick}
                              rowsData={context.archives}
                              isLoading={context.isLoading}
                              showCellMenu={true}
                              cellMenuItems={[
                                {
                                  lable: t("delete_archive_menu_item"),
                                  action: AUTableCellMenuAction.DELETE_ARCHIVE,
                                },
                                {
                                  lable: t("edit_archive_menu_item"),
                                  action: AUTableCellMenuAction.EDIT_ARCHIVE,
                                },
                              ]}
                            />
                          </Box>
                        </Container>
                      ) : (
                        <Box
                          sx={{
                            my: 2,
                            minHeight: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <TypeAnimation
                            sequence={[
                              t("welcome_to_aureka"),
                              2000,
                              t("lets_get_started"),
                              2000,
                            ]}
                            wrapper="h3"
                            cursor={true}
                            repeat={Infinity}
                            style={{
                              fontSize: "2em",
                              color: theme.palette.primary.main,
                            }}
                          />
                          <Typography
                            sx={{
                              maxWidth: "500px",
                              marginBottom: 2,
                              textAlign: "center",
                            }}
                            variant="body2"
                          >
                            {t("empty_archives_page_description")}
                          </Typography>
                          <Button
                            variant="contained"
                            onClick={() => router.push("/archives/add")}
                          >
                            {t("create_archive_button")}
                          </Button>
                          <img
                            src="/assets/Logos/Produkt-Einstiegsgrafik.png"
                            style={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "35%",
                              transform: "scaleX(-1)",
                            }}
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <BrandProgress />
                  )}
                </>
              )}
            </ArchivesTableContext.Consumer>
          </ArchivesTableContextProvider>
        </>
      )}
    </Container>
  )
}

export default ProtectedPage(Archives)

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  }
}
