import * as React from "react"
import { styled } from "@mui/material/styles"
import {
  Grid,
  IconButton,
  tableCellClasses,
  TableRow,
  Typography,
  TableCell,
  TableSortLabel,
  TableHead,
  Box,
} from "@mui/material"

import {
  EnhancedTableProps,
  AUListItem,
  AUSearchResultListItemDoc,
} from "../models"

import theme from "../../theme"
import { visuallyHidden } from "@mui/utils"
import { ChevronRight } from "@mui/icons-material"
import { au_border_radius } from "../themeConstants"
import { Edit, Trash } from "react-feather"
import AUTableCellMenu from "../../AUTableCellMenu"

export function numberOfPages(rowCount, rowsPerPage): number {
  if (rowCount > 0 && rowsPerPage > 0) {
    if (rowCount === 1) {
      return 1
    } else {
      return Math.ceil(rowCount / rowsPerPage)
    }
  }
  return 0
}

export function AUListCell({
  row,
  onCellCallback,
  isDense = false,
  showCellMenu = false,
  cellMenuItems = [],
}) {
  return (
    <Grid container alignItems={"center"} justifyContent={"space-between"}>
      <Grid item flex={1} onClick={() => onCellCallback(row)}>
        <Typography
          sx={{ fontWeight: 600, fontSize: isDense ? "0.8rem" : "1rem" }}
          variant={"h5"}
        >
          {row.name}
        </Typography>
      </Grid>

      <Grid item>
        {onCellCallback ? (
          <IconButton
            onClick={() => onCellCallback(row)}
            id="search"
            size="large"
          >
            <ChevronRight color={"primary"} />
          </IconButton>
        ) : (
          "<></>"
        )}
      </Grid>
      {showCellMenu && (
        <Grid item>
          <AUTableCellMenu
            row={row}
            menuItems={cellMenuItems}
          ></AUTableCellMenu>
        </Grid>
      )}
    </Grid>
  )
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const styleForHeaderRow = (index: number, length: number) => {
    if (index === 0 && length === 1) {
      return {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: au_border_radius,
        borderTopRightRadius: au_border_radius,
      }
    }

    if (index === 0) {
      return {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: au_border_radius,
      }
    }

    if (index === length - 1) {
      return {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        borderTopRightRadius: au_border_radius,
      }
    }
  }

  const { order, orderBy, rowCount, onRequestSort } = props
  const createSortHandler =
    (property: keyof AUListItem) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead
      sx={{
        borderTopLeftRadius: au_border_radius,
        overflow: "hidden",
      }}
    >
      <TableRow>
        {props.data.map((headCell, index) => (
          <TableCell
            sx={styleForHeaderRow(index, props.data.length)}
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              style={{
                fontSize: "0.8rem",
                fontWeight: 600,
                fontFamily: "Libre Franklin",
                margin: 0,
                color: "white",
              }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export const ShortTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "2rem",
  },
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))
